import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

export const IconCalendar = ({ classProps }: PropsWithClassProps) => {
  return (
    <svg className={classProps?.root} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 5C9.5 4.58579 9.16421 4.25 8.75 4.25C8.33579 4.25 8 4.58579 8 5H9.5ZM16 5C16 4.58579 15.6642 4.25 15.25 4.25C14.8358 4.25 14.5 4.58579 14.5 5H16ZM7.25 8H16.75V6.5H7.25V8ZM18 9.25V16.5H19.5V9.25H18ZM16.75 17.75H7.25V19.25H16.75V17.75ZM6 16.5V9.25H4.5V16.5H6ZM7.25 17.75C6.55964 17.75 6 17.1904 6 16.5H4.5C4.5 18.0188 5.73122 19.25 7.25 19.25V17.75ZM18 16.5C18 17.1904 17.4404 17.75 16.75 17.75V19.25C18.2688 19.25 19.5 18.0188 19.5 16.5H18ZM16.75 8C17.4404 8 18 8.55964 18 9.25H19.5C19.5 7.73122 18.2688 6.5 16.75 6.5V8ZM7.25 6.5C5.73122 6.5 4.5 7.73122 4.5 9.25H6C6 8.55964 6.55964 8 7.25 8V6.5ZM8 5V7.25H9.5V5H8ZM14.5 5V7.25H16V5H14.5ZM5.25 11.25H18.75V9.75H5.25V11.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
