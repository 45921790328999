import React, { useMemo, useState } from 'react';
import { composeStyles } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-react-components';
import {
  addDays,
  addWeeks,
  format,
  isAfter,
  isBefore,
  isMonday,
  isSameDay,
  isSameMonth,
  previousMonday,
  subDays,
} from 'date-fns';
import { de } from 'date-fns/locale/de';
import { TV_DAY_COUNT } from 'tvm-config';
import { IconCarretRight } from '@src/assets/icon-carret-right';
import { CalendarDay } from './CalendarDay';

export interface CalendarProps extends PropsWithClassProps {
  selectedDate: Date;
  handleDateChange: (newDate: Date) => void;
  defaultValue?: string;
}

export const Calendar = ({ selectedDate, handleDateChange, classProps }: CalendarProps) => {
  const [weekOffset, setWeekOffset] = useState<number>(0);
  const startOfWeekDate = useMemo(
    () => addWeeks(isMonday(selectedDate) ? selectedDate : previousMonday(selectedDate), weekOffset),
    [selectedDate, weekOffset],
  );

  const endOfWeekDate = addDays(startOfWeekDate, 6);
  const today = new Date();
  const firstAvailableDay = subDays(today, TV_DAY_COUNT / 2);
  const lastAvailableDay = addDays(today, TV_DAY_COUNT / 2);

  const weekDays = useMemo(() => Array.from({ length: 7 }, (_, i) => addDays(startOfWeekDate, i)), [startOfWeekDate]);

  const firstDayTitle = format(startOfWeekDate, isSameMonth(startOfWeekDate, endOfWeekDate) ? 'dd.' : 'dd. MMMM', {
    locale: de,
  });
  const lastDayTitle = format(endOfWeekDate, 'dd. MMMM yyyy', {
    locale: de,
  });
  const weekTitle = `${firstDayTitle} - ${lastDayTitle}`;

  function isInactive(day: Date) {
    return isBefore(day, firstAvailableDay) || isAfter(day, lastAvailableDay);
  }

  const defaultStyle = {
    root: 'mt-5 rounded-[20px] bg-gray-700 py-4 sm:py-6 md:min-w-[480px]',
  };

  const styles = composeStyles(defaultStyle, classProps);

  return (
    <div className={styles.root}>
      <div className="relative mt-5 grid grid-cols-[auto,1fr,auto] content-center items-center px-4 sm:px-5">
        <button
          onClick={() => {
            setWeekOffset((prev) => prev - 1);
          }}
          className="z-10 inline-grid size-9 place-items-center rounded-full border border-gray-650/25 bg-gray-820/70 transition-colors duration-200 hover:border-white"
        >
          <IconCarretRight
            classProps={{
              root: 'rotate-180',
            }}
          />
        </button>
        <div className="heading-5 w-full px-5 text-center font-semibold text-white">{weekTitle}</div>
        <button
          onClick={() => {
            setWeekOffset((prev) => prev + 1);
          }}
          className="z-10 inline-grid size-9 place-items-center rounded-full border border-gray-650/25 bg-gray-820/70 transition-colors duration-200 hover:border-white"
        >
          <IconCarretRight
            classProps={{
              root: '',
            }}
          />
        </button>
      </div>
      <div className="mt-4 grid grid-cols-7 gap-1 px-4 sm:mx-6 sm:mt-5 sm:gap-2.5">
        {weekDays.map((day) => (
          <CalendarDay
            key={day?.getTime()}
            day={day}
            selected={isSameDay(day, selectedDate)}
            onDayClick={handleDateChange}
            inactive={isInactive(day)}
            isCurrentDay={isSameDay(day, today)}
          />
        ))}
      </div>
    </div>
  );
};
