import { forwardRef, useState } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { addDays, format, subDays } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { useShallow } from 'zustand/react/shallow';
import { IconCalendar } from '@src/assets/icon-calendar';
import { IconClose } from '@src/assets/icon-close';
import { IconNext } from '@src/assets/icon-next';
import { IconPrev } from '@src/assets/icon-prev';
import { useTvGuideStore } from '@src/stores/tvguide';
import { Calendar } from './Calendar/Calendar';

export interface DatePickerProps extends PropsWithClassProps {
  reloadEveryPlacedAdslot: () => void;
}
export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(({ reloadEveryPlacedAdslot }, ref) => {
  const { setActiveDay, activeGuideDay } = useTvGuideStore(
    useShallow((state) => ({
      setActiveDay: state.setActiveGuideDay,
      activeGuideDay: state.activeGuideDay,
    })),
  );

  const [showCalendar, setShowCalendar] = useState(false);

  if (!activeGuideDay) return null;

  const handleDateChange = (newDate) => {
    setActiveDay(newDate);
    toggleCalendar();
  };

  const toggleCalendar = () => {
    setShowCalendar(false);
  };

  const handleCalendarClick = () => {
    if (showCalendar) {
      toggleCalendar();
    } else {
      setShowCalendar(!showCalendar);
    }
  };

  return (
    <div className="relative flex h-12 w-full justify-center gap-3 rounded-full bg-gray-700 md:max-w-[500px]" ref={ref}>
      {!showCalendar && (
        <button
          onClick={() => {
            handleDateChange(subDays(activeGuideDay, 1));
            reloadEveryPlacedAdslot();
          }}
          className="group relative grid h-full w-20 place-items-center rounded-full transition-colors duration-200 hover:bg-gray-500 focus:outline-none"
        >
          <IconPrev
            classProps={{
              root: 'w-6 h-2.5',
            }}
          />
          <div
            role="separator"
            className="absolute inset-y-3 right-0 w-px bg-gray-600 transition-opacity duration-200 group-hover:opacity-0"
          ></div>
        </button>
      )}
      <button
        className="flex w-full items-center justify-between rounded-full px-5 text-base font-semibold text-white transition-colors duration-200 hover:bg-gray-500 focus:outline-none"
        onClick={handleCalendarClick}
      >
        <span>{showCalendar ? 'Bitte Tag auswählen' : format(activeGuideDay, 'dd. MMMM yyyy', { locale: de })}</span>
        {showCalendar ? (
          <IconClose
            classProps={{
              root: 'w-6 h-4',
            }}
          />
        ) : (
          <IconCalendar
            classProps={{
              root: 'w-7 h-7',
            }}
          />
        )}
      </button>
      {!showCalendar && (
        <button
          onClick={() => {
            handleDateChange(addDays(activeGuideDay, 1));
            reloadEveryPlacedAdslot();
          }}
          className="group relative grid h-full w-20 place-items-center rounded-full transition-colors duration-200 hover:bg-gray-500 focus:outline-none"
        >
          <IconNext
            classProps={{
              root: 'w-6 h-2.5',
            }}
          />
          <div
            role="separator"
            className="absolute inset-y-3 left-0 w-px bg-gray-600 transition-opacity duration-200 group-hover:opacity-0"
          ></div>
        </button>
      )}
      {showCalendar && (
        <Calendar
          selectedDate={activeGuideDay}
          handleDateChange={handleDateChange}
          classProps={{ root: 'absolute top-full inset-x-0 z-40' }}
        />
      )}
    </div>
  );
});

DatePicker.displayName = 'DatePicker';
