import React from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { twMerge } from 'tailwind-merge';

export interface CalendarDayProps {
  day: Date;
  currentDay: Date;
  inactive?: boolean;
  onDayClick: (date: Date) => void;
}

export const CalendarDay = ({ day, isCurrentDay, inactive, selected, onDayClick }) => {
  const styles = {
    root: 'cursor-pointer rounded-[9px] border border-transparent px-1 xs:px-2 py-2 text-center hover:border-gray-650 hover:bg-gray-820 hover:text-white min-w-0',
    selected: 'rounded-[9px] bg-white text-black hover:border-white hover:bg-gray-820 hover:text-white',
    current: 'text-primary hover:rounded-[9px] hover:border-primary hover:bg-gray-820 hover:text-primary',
    inactive: 'text-gray-500',
  };

  return (
    <div
      className={twMerge(
        styles.root,
        isCurrentDay && styles.current,
        inactive && styles.inactive,
        selected && styles.selected,
        isCurrentDay && selected && 'text-primary',
      )}
      onClick={() => onDayClick(day)}
    >
      <div>{day.getDate()}</div>
      <div className="label-sm mt-1 xs:mt-3">{format(day, 'iiiiii', { locale: de })}</div>
    </div>
  );
};
